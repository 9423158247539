:root {
    --app-height: 100%;
    --font: "Inter", serif;
    --background: #ffffff;
    --background-active: #d2e5fd;
    --backgropund-page: #F9FAFB;
    --box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.23);
    --pagging: 20px;
    --color-main: #1979BF;
    --color-text: #333333;
    --color-text-medium: #585555;
    --color-text-light: #848484;
    --color-success: #4DA34D;
    --color-error: #DF5A5A;
}

@font-face {
    font-family: "Inter";
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/Inter_18pt-Regular.ttf') format('truetype');
}

@font-face {
    font-family: "Inter";
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/Inter_18pt-Medium.ttf') format('truetype');
}

@font-face {
    font-family: "Inter";
    font-weight: 600;
    font-style: normal;
    src: url('./assets/fonts/Inter_18pt-Bold.ttf') format('truetype');
}


* {
    box-sizing: border-box;
    font-family: "Inter", serif;
}

html,
body {
    padding: 0;
    margin: 0;
    background: var(--backgropund-page);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
    border: none;
    outline: none;
}

input {
    border: none;
    outline: none;
}

input:active {
    outline: none;
}

button:hover {
    cursor: pointer;
}

.ant-select .ant-select-selector {
    width: 100%;
    border: none !important;
}

.ant-select-focused {
    box-shadow: none !important;
}

.ant-select-selector {
    box-shadow: none !important;
}

.fade-in-svg {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}
